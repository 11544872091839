.cardContent {
  width: 60%;
}

@media screen and (max-width: 768px) {
  .cardContent {
    width: 100%;
    padding: 5px !important;
  }
}
