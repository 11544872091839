.siteContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

@media screen and (max-width: 768px) {
  .siteContainer {
    flex-direction: column;
  }

  .siteContainer div {
    margin-bottom: 15px;
    width: 100% !important;
  }
}
