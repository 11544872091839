.cropperBtnStrip {
  z-index: 1001;
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 35px;
  position: fixed;
}

.cropperOverlay {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000000;
  z-index: 1000;
  left: 0;
  right: 0;
}

.cropperContainer div {
  background-image: none;
  background-color: gray;
}

.cropperBtn {
  z-index: 100;
  margin: 10px !important;
  background-color: #39f !important;
  color: white !important;
}

.rotateBtn {
  z-index: 100;
  align-self: flex-start;
  margin: 10px !important;
  background-color: #39f !important;
  color: white !important;
}

.rotateBtnReverse {
  margin: 10px !important;
  background-color: #39f !important;
  color: white !important;
  z-index: 100;
  align-self: flex-start;
  transform: rotateY(180deg);
}
