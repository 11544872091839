body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MuiFormLabel-root.Mui-focused {
  bottom: 66% !important;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
  bottom: unset !important;
}

input.MuiInputBase-input.MuiOutlinedInput-input::placeholder {
  color: #999 !important;
  /* opacity: 0.8; */
  /* font-weight: 600; */
}

input.MuiInputBase-input.MuiOutlinedInput-input:hover {
  border: none !important;
  /* opacity: 1; */
}

.MuiOutlinedInput-root {
  border-radius: 0 !important;
  border: none !important;
}

.MuiOutlinedInput-root:hover {
  border: none !important;
}
